/***
*
*   HOMEPAGE
*   Homepage template with features, testimonials and CTA
*
**********/

import React, { Fragment } from 'react';
import { Row, Button, Hero, Features, Testimonial } from 'components/lib';
import ImgDashboard from './images/dashboard.png';
import ImgKyleGawley from './images/kyle-gawley.jpg';

export function Home(props){

  return(
    <Fragment>

      <Hero
        title='Find website issues before your users do.'
        tagline='Find quality issues and improvements for your website in minutes by our automated monkey testing.Try now for free.'
      />

      <Row title='Meet Our Monkeys' color='blue'>
        <Features />
      </Row>

    </Fragment>
  );
}



