/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React from 'react';
import { Card, Stat, ProgressBar, Chart, Table,
  Message, Grid, Animate, Feedback, useAPI } from 'components/lib';

export function Dashboard(props){

  const stats = useAPI('/api/demo/stats');
  const progress = useAPI('/api/demo/progress');
  const table = useAPI('/api/demo/users/list');
  const userChart = useAPI('/api/demo/users/types');
  const revenueChart = useAPI('/api/demo/revenue');

  return (
    <Animate type='pop'>

      <Message
        closable
        title='Welcome to MonkeyTest AI!'
        text='This is a sample dashboard to see how it looks ,please wait while the actual dashboard is prepared'
        type='info'
      />

      <Grid cols='4'>
        <Stat
          loading={ stats?.loading }
          value={ stats?.data?.users }
          label='passed checks'
          icon='check-circle'
        />
        <Stat
          loading={ stats?.loading }
          value={ stats?.data?.active }
          label='failed checks'
          icon='alert-cicle'
        />
        <Stat
          loading={ stats?.loading }
          value={ stats?.data?.churned }
          label='open issues'
          icon='alert-cicle'
        />
        <Stat
          loading={ stats?.loading }
          value={ stats?.data?.latest }
          label='runs this month'
          icon='calendar'
          change='5%'
        />
      </Grid>

      <Card name='revenues' title='Tool'>
 <p align="center"> <iframe  src="https://beta.checkops.com/" width="100%" height="1200" frameborder="0"> </iframe></p>
      </Card>

      <Feedback />

    </Animate>
  );
}
