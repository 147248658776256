import { Grid, ClassHelper } from 'components/lib';
import Style from './features.tailwind.js';

export function Features(props) {
  const data = [
    { avatar: 'https://pic.funnygifsbox.com/uploads/2018/10/funnygifsbox.com-2018-10-19-10-34-07-78.gif', title: 'Explorer Monkey', desc: 'Explore your page and check all resources for speed to suggest improvements' },
    { avatar: 'https://emojis.slackmojis.com/emojis/images/1643514240/2086/reading.gif?1643514240', title: 'Nerd Monkey', desc: 'Reads your page content,links and design to identify issues like deadlinks and best practices ' },
    { avatar: 'https://emojis.slackmojis.com/emojis/images/1643514239/2078/looking.gif?1643514239', title: 'Detective Monkey', desc: 'Detects errors on your page across be it network , javascript or page CTAs' },
  ];

  return (
    <Grid cols={3}>
      {data.map((item, index) => (
        <Feature
          key={index}
          avatar={item.avatar}
          title={item.title}
          desc={item.desc}
        />
      ))}
    </Grid>
  );
}

function Feature(props) {
  const featureStyle = ClassHelper(Style, {
    feature: true,
    className: props.className,
  });

  return (
    <div className={featureStyle}>
      <img src={props.avatar} alt="Avatar" className={Style.avatar} width="80"/>

      <h3 className={Style.title}>{props.title}</h3>

      <p className={Style.description}>{props.desc}</p>
    </div>
  );
}
